import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface NewsItem {
  title: string;
  link: string;
  pubDate: string;
  description: string;
  imageUrl?: string;
}

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  background-color: #f5f7fa;
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
`;

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const NewsCard = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.5s ease-out forwards;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const NewsImage = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const NewsContent = styled.div`
  padding: 1.5rem;
`;

const NewsTitle = styled.h2`
  color: #34495e;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const NewsDate = styled.p`
  color: #7f8c8d;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const NewsDescription = styled.p`
  color: #2c3e50;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ReadMore = styled.a`
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 2rem;
  padding: 1rem;
  background-color: #fde2e2;
  border-radius: 8px;
`;

const News: React.FC = () => {
  const [items, setItems] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const response = await fetch(
          'https://integrameli.com.br/category/integrameli/feed/?posts_per_page=11'
        );
        const text = await response.text();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, 'text/xml');

        // Usando XPath para selecionar todos os elementos 'item'
        const xPathQuery = '//item';
        const itemsSnapshot = xmlDoc.evaluate(
          xPathQuery,
          xmlDoc,
          null,
          XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
          null
        );

        const extractedItems: NewsItem[] = [];
        for (let i = 0; i < itemsSnapshot.snapshotLength; i++) {
          const item = itemsSnapshot.snapshotItem(i) as Element;

          const title =
            item.querySelector('title')?.textContent ?? 'Sem título';
          const link = item.querySelector('link')?.textContent ?? '#';
          const pubDate =
            item.querySelector('pubDate')?.textContent ?? 'Sem data';
          const description =
            item.querySelector('description')?.textContent ?? 'Sem descrição';
          const contentEncoded =
            item.getElementsByTagName('content:encoded')[0]?.textContent ?? '';

          const imageUrl = extractImageUrl(contentEncoded, description);

          extractedItems.push({ title, link, pubDate, description, imageUrl });
        }

        setItems(extractedItems);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao buscar o feed:', err);
        setError(
          'Falha ao carregar as notícias. Por favor, tente novamente mais tarde.'
        );
        setLoading(false);
      }
    };

    fetchFeed();
  }, []);

  const extractImageUrl = (
    content: string,
    description: string
  ): string | undefined => {
    const imgRegexes = [
      /<img[^>]+src="?([^"\s]+)"?\s*\/?>/i,
      /<media:content[^>]+url="?([^"\s]+)"?\s*\/?>/i,
      /src="([^"]+)"/i,
      /url="([^"]+)"/i,
    ];

    for (let regex of imgRegexes) {
      let match = content.match(regex) || description.match(regex);
      if (match) {
        return match[1];
      }
    }

    return undefined;
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <Container>
      <Title>Últimas Atualizações</Title>
      {items.length === 0 ? (
        <p>Nenhuma atualização encontrada.</p>
      ) : (
        <NewsGrid>
          {items.map((item, index) => (
            <NewsCard key={index}>
              {item.imageUrl && (
                <NewsImage
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                />
              )}
              <NewsContent>
                <NewsTitle>{item.title}</NewsTitle>
                <NewsDate>
                  {new Date(item.pubDate).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </NewsDate>
                <NewsDescription>{item.description}</NewsDescription>
                <ReadMore
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Leia mais
                </ReadMore>
              </NewsContent>
            </NewsCard>
          ))}
        </NewsGrid>
      )}
    </Container>
  );
};

export default News;
