import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment'; // Para manipular datas

interface TrialCheckProps {
  children: React.ReactNode; // Conteúdo da página que será renderizado se o trial for válido
}

const TrialCheck: React.FC<TrialCheckProps> = ({ children }) => {
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const [trialExpired, setTrialExpired] = useState(false);

  useEffect(() => {
    // Verifica o papel do usuário e a data de criação no sessionStorage
    const userRole = sessionStorage.getItem('roleModulo');
    const createdAt = sessionStorage.getItem('created_at');

    if (userRole === 'trial' && createdAt) {
      const createdDate = moment(createdAt); // Data de criação do usuário
      const currentDate = moment(); // Data atual
      const daysDifference = currentDate.diff(createdDate, 'days'); // Diferença em dias

      if (daysDifference > 7) {
        setTrialExpired(true); // Se o trial expirou, marca como expirado
        setShowExpiredModal(true); // Exibe o modal de pagamento
      } else {
        setShowTrialModal(true); // Exibe o modal informando que o usuário está no trial
      }
    }
  }, []);

  const handleCloseTrialModal = () => setShowTrialModal(false);
  const handleCloseExpiredModal = () => setShowExpiredModal(false);

  return (
    <>
      {/* Modal de aviso que o usuário está no período de trial */}
      <Modal show={showTrialModal} onHide={handleCloseTrialModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bem-vindo ao IntegraMeli</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Você está usando a versão de teste do IntegraMeli.</p>
          <p>
            Você tem 7 dias para testar todas as funcionalidades. Aproveite!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTrialModal}>
            Ok, entendi
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de aviso que o trial expirou */}
      <Modal show={showExpiredModal} onHide={handleCloseExpiredModal}>
        <Modal.Header closeButton>
          <Modal.Title>Plano Trial Expirado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Seu plano de teste de 7 dias expirou.</p>
          <p>
            Para continuar usando o IntegraMeli, por favor, realize o pagamento
            através do link abaixo:
          </p>
          <a
            href="https://pay.kiwify.com.br/gcYsh69"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            Efetuar Pagamento
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseExpiredModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Se o trial não expirou, renderiza o conteúdo da página */}
      {!trialExpired && children}
    </>
  );
};

export default TrialCheck;
