import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para adicionar token de autenticação
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para tratar respostas de erro
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Verifique se a requisição era uma tentativa de login
    const isLoginRequest = error.config.url.endsWith('/login');
    if (error.response && error.response.status === 401 && !isLoginRequest) {
      toast.error('Sessão expirada. Faça login novamente.');
      window.location.href = '/login';
    } else if (error.response && error.response.status === 404) {
      console.log(error.response.data);
    } else {
      toast.error(
        error.response?.data?.message || 'Ocorreu um erro. Tente novamente.'
      );
    }
    return Promise.reject(error);
  }
);

export default api;
