import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Token {
  id: number;
  meliConta_id: number;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  created_at: string;
  updated_at: string;
  accountName?: string;
}

interface TokensProps {
  updated: boolean;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
`;

const TokenGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const TokenCard = styled.div<{ isValid: boolean }>`
  background-color: ${props => props.isValid ? '#ffffff' : '#fff8e1'};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const AccountName = styled.h2`
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 1rem;
`;

const TokenInfo = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
`;

const TokenValue = styled.div`
  background-color: #f1f3f5;
  border-radius: 5px;
  padding: 0.5rem;
  font-family: monospace;
  font-size: 0.8rem;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CopyButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${TokenValue}:hover & {
    opacity: 1;
  }
`;

const StatusIndicator = styled.div<{ isValid: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => props.isValid ? '#27ae60' : '#e74c3c'};
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const LoadingSpinner = styled(Spinner)`
  margin: 2rem auto;
  display: block;
`;

const StyledAlert = styled(Alert)`
  max-width: 500px;
  margin: 2rem auto;
`;

const Tokens: React.FC<TokensProps> = ({ updated }) => {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [copiedId, setCopiedId] = useState<number | null>(null);

  useEffect(() => {
    fetchTokens();
  }, [updated]);

  const fetchTokens = async () => {
    setLoading(true);
    setError(null);
    try {
      const [tokensResponse, accountsResponse] = await Promise.all([
        axios.get(`${import.meta.env.VITE_APP_API_URL}/meli-tokens`, {
          headers: { 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}` }
        }),
        axios.get(`${import.meta.env.VITE_APP_API_URL}/meli-contas`, {
          headers: { 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}` }
        })
      ]);
      
      const tokens = tokensResponse.data.data as Token[];
      const accounts = accountsResponse.data.data as { id: number; nome: string }[];
      
      const tokensWithAccountNames = tokens.map(token => ({
        ...token,
        accountName: accounts.find(acc => acc.id === token.meliConta_id)?.nome || 'Conta desconhecida'
      }));

      setTokens(tokensWithAccountNames);
    } catch (error) {
      console.error('Erro ao buscar tokens ou contas:', error);
      setError('Erro ao buscar tokens ou contas.');
    } finally {
      setLoading(false);
    }
  };

  const isTokenValid = (token: Token) => {
    const updatedAt = new Date(token.updated_at).getTime();
    const now = new Date().getTime();
    const elapsedSeconds = (now - updatedAt) / 1000;
    return elapsedSeconds < token.expires_in;
  };

  const handleCopy = (id: number) => {
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 2000);
  };

  if (loading) {
    return <LoadingSpinner animation="border" />;
  }

  if (error) {
    return <StyledAlert variant="danger">{error}</StyledAlert>;
  }

  return (
    <PageContainer>
      <Title>Tokens do Mercado Livre</Title>
      <TokenGrid>
        {tokens.map((token) => {
          const isValid = isTokenValid(token);
          return (
            <TokenCard key={token.id} isValid={isValid}>
              <AccountName>{token.accountName}</AccountName>
              <TokenInfo>Access Token:</TokenInfo>
              <TokenValue>
                {token.access_token.substring(0, 20)}...
                <CopyToClipboard text={token.access_token} onCopy={() => handleCopy(token.id)}>
                  <CopyButton>
                    <FontAwesomeIcon icon={copiedId === token.id ? faCheckCircle : faCopy} />
                  </CopyButton>
                </CopyToClipboard>
              </TokenValue>
              <TokenInfo>Refresh Token:</TokenInfo>
              <TokenValue>
                {token.refresh_token.substring(0, 20)}...
                <CopyToClipboard text={token.refresh_token} onCopy={() => handleCopy(token.id)}>
                  <CopyButton>
                    <FontAwesomeIcon icon={copiedId === token.id ? faCheckCircle : faCopy} />
                  </CopyButton>
                </CopyToClipboard>
              </TokenValue>
              <TokenInfo>Expira em: {token.expires_in} segundos</TokenInfo>
              <TokenInfo>Última Atualização: {new Date(token.updated_at).toLocaleString('pt-BR')}</TokenInfo>
              <StatusIndicator isValid={isValid}>
                <FontAwesomeIcon icon={isValid ? faCheckCircle : faExclamationTriangle} />
                <span style={{ marginLeft: '0.5rem' }}>
                  {isValid ? 'Token Válido' : 'Token Expirado'}
                </span>
              </StatusIndicator>
            </TokenCard>
          );
        })}
      </TokenGrid>
    </PageContainer>
  );
};

export default Tokens;