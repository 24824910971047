import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import 'chart.js/auto';
import Sentiment from 'sentiment';
import ExcelJS from 'exceljs';
import { toast } from 'react-toastify';
import { Spinner, Badge, Alert } from 'react-bootstrap';
import { Produto } from './type';
import Analises from './Analises';
import FiltroVendedores from './FiltroVendedores';
import ResultadosBusca from './ResultadosBusca';
import FiltroBusca from './FiltroBuscaProps';
import ComparisonModal from './ComparisonModal';
import './index.css';
import { refreshToken } from '../Token/refreshToken';
import { useLocation } from 'react-router-dom'; // Importa o useLocation para capturar o parâmetro da URL

interface Categoria {
  id: string;
  name: string;
}

interface ProdutosQueryResult {
  produtos: Produto[];
  totalPaginas: number;
  paginaAtual: number;
}

const BuscaPreco: React.FC = () => {
  const location = useLocation(); // Obtém a localização atual da URL
  // Input states
  const [inputTermoBusca, setInputTermoBusca] = useState<string>('');
  const [inputPrecoMinimo, setInputPrecoMinimo] = useState<string>('');
  const [inputPrecoMaximo, setInputPrecoMaximo] = useState<string>('');
  const [inputFiltroAvaliacao, setInputFiltroAvaliacao] = useState<string>('');
  const [inputFiltroFrete, setInputFiltroFrete] = useState<string>('');
  const [inputFiltroDesconto, setInputFiltroDesconto] =
    useState<boolean>(false);
  const [inputFiltroCategoria, setInputFiltroCategoria] = useState<string>('');
  const [inputFiltroCondicao, setInputFiltroCondicao] = useState<string>('');
  const [inputFiltroDisponibilidade, setInputFiltroDisponibilidade] =
    useState<string>('');
  const [inputFiltroMarca, setInputFiltroMarca] = useState<string>('');
  const [inputFiltroLocalidade, setInputFiltroLocalidade] =
    useState<string>('');
  const [inputOrdenacao, setInputOrdenacao] = useState<string>(''); // Novo estado para ordenação
  const [, setLoading] = useState<boolean>(false);
  const selectedAccount = '7'; // Defina sua conta selecionada ou obtenha de algum lugar

  // Search parameters
  const [searchParams, setSearchParams] = useState({
    termoBusca: '',
    precoMinimo: '',
    precoMaximo: '',
    filtroAvaliacao: '',
    filtroFrete: '',
    filtroDesconto: false,
    filtroCategoria: '',
    filtroCondicao: '',
    filtroDisponibilidade: '',
    filtroMarca: '',
    filtroLocalidade: '',
    ordenacao: '', // Incluindo ordenação nos parâmetros de busca
  });

  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState<string[]>(
    []
  );
  const [offset, setOffset] = useState<number>(0);
  const [limite, setLimite] = useState<number>(50);
  const [vendedores, setVendedores] = useState<string[]>([]);
  const [vendedoresSelecionados, setVendedoresSelecionados] = useState<
    string[]
  >([]);
  const [sentimentos, setSentimentos] = useState<{
    positivo: number;
    negativo: number;
    neutro: number;
  }>({ positivo: 0, negativo: 0, neutro: 0 });
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);
  const [selecionarTodos, setSelecionarTodos] = useState(false);
  const [marcas, setMarcas] = useState<string[]>([]);
  const [localidades, setLocalidades] = useState<string[]>([]);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const [paginaAtual, setPaginaAtual] = useState<number>(1);
  const [mediaPreco, setMediaPreco] = useState<number>(0);
  const [produtosComparacao, setProdutosComparacao] = useState<Produto[]>([]);
  const [showComparacaoModal, setShowComparacaoModal] =
    useState<boolean>(false);
  const [pesquisaRealizada, setPesquisaRealizada] = useState(false);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const token = sessionStorage.getItem('ml_access_token');

  const fetchCategorias = async (): Promise<Categoria[]> => {
    const response = await axios.get(
      'https://api.mercadolibre.com/sites/MLB/categories'
    );
    return response.data;
  };

  const { data: categoriasData } = useQuery<Categoria[], Error>({
    queryKey: ['categoriasTop'],
    queryFn: fetchCategorias,
    refetchInterval: 600000,
  });

  const fetchProdutos = async (): Promise<ProdutosQueryResult> => {
    const params: any = {
      q: searchParams.termoBusca,
      limit: limite,
      offset: offset,
    };

    if (searchParams.precoMinimo)
      params.price = `${searchParams.precoMinimo}-*`;
    if (searchParams.precoMaximo)
      params.price = `${params.price ? params.price.split('-')[0] : '*'}-${
        searchParams.precoMaximo
      }`;
    if (searchParams.filtroAvaliacao)
      params.seller_reputation = searchParams.filtroAvaliacao;
    if (searchParams.filtroFrete === 'gratis') params.shipping = 'free';
    if (searchParams.filtroDesconto) params.has_discount = true;
    if (searchParams.filtroCategoria)
      params.category = searchParams.filtroCategoria;
    if (searchParams.filtroCondicao)
      params.condition = searchParams.filtroCondicao;
    if (searchParams.filtroDisponibilidade)
      params.available_quantity =
        searchParams.filtroDisponibilidade === 'disponivel' ? '1-*' : '0';
    if (searchParams.filtroMarca) params.MARKETPLACE = searchParams.filtroMarca;
    if (searchParams.filtroLocalidade)
      params.city = searchParams.filtroLocalidade;
    if (searchParams.ordenacao) params.sort = searchParams.ordenacao; // Adicionando o parâmetro de ordenação

    const response = await axios.get(
      'https://api.mercadolibre.com/sites/MLB/search',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params, // Parâmetros de busca
      }
    );

    if (response.status === 200) {
      const produtosData: Produto[] = response.data.results;

      const produtosDetalhados = await Promise.all(
        produtosData.map(async (produto) => {
          try {
            const detalheResponse = await axios.get(
              `https://api.mercadolibre.com/items/${produto.id}`
            );

            // Atualiza diretamente o produto.seller_address.city.name com a localidade obtida
            const localidade =
              detalheResponse.data.seller_address?.city?.name || 'Desconhecida';

            // Verifica se o campo seller_address existe no produto, se não, cria-o
            if (!produto.seller_address) {
              produto.seller_address = { city: { name: localidade } };
            } else if (!produto.seller_address.city) {
              produto.seller_address.city = { name: localidade };
            } else {
              produto.seller_address.city.name = localidade;
            }
            // console.log(produto);
            return produto; // Retorna o produto atualizado
          } catch (error) {
            console.error(
              `Erro ao obter detalhes do produto ${produto.id}:`,
              error
            );
            return produto; // Retorna o produto original se houver erro
          }
        })
      );

      return {
        produtos: produtosDetalhados,
        totalPaginas: Math.ceil(response.data.paging.total / limite),
        paginaAtual: offset / limite + 1,
      };
    }

    return { produtos: [], totalPaginas: 0, paginaAtual: 1 };
  };

  const {
    data: produtosData,
    isLoading,
    refetch: refetchProdutos,
  } = useQuery<ProdutosQueryResult>({
    queryKey: [
      'produtos',
      searchParams.termoBusca,
      limite,
      offset,
      searchParams.precoMinimo,
      searchParams.precoMaximo,
      searchParams.filtroAvaliacao,
      searchParams.filtroFrete,
      searchParams.filtroDesconto,
      searchParams.filtroCategoria,
      searchParams.filtroCondicao,
      searchParams.filtroMarca,
      searchParams.filtroLocalidade,
      searchParams.ordenacao, // Incluindo ordenação na chave da consulta
    ],
    queryFn: fetchProdutos,
    enabled: !!searchParams.termoBusca,
    staleTime: 5 * 60 * 1000, // 5 minutos
    gcTime: 30 * 60 * 1000, // 30 minutos
  });

  useEffect(() => {
    if (produtosData) {
      setProdutos(produtosData.produtos);
      setTotalPaginas(produtosData.totalPaginas);
      setPaginaAtual(produtosData.paginaAtual);

      const vendedoresUnicos = Array.from(
        new Set(
          produtosData.produtos.map(
            (produto: Produto) => produto.seller?.nickname || 'Não informado'
          )
        )
      );
      setVendedores(vendedoresUnicos);

      const marcasUnicas = Array.from(
        new Set(
          produtosData.produtos
            .map(
              (produto: Produto) =>
                produto.attributes?.find(
                  (attr: { id: string }) => attr.id === 'BRAND'
                )?.value_name || 'Não informado'
            )
            .filter((marca: string) => marca !== 'Não informado')
        )
      );
      setMarcas(marcasUnicas);

      const localidadesUnicas = Array.from(
        new Set(
          produtosData.produtos
            .map(
              (produto: Produto) =>
                produto.seller_address?.city?.name || 'Não informado'
            )
            .filter((local: string) => local !== 'Não informado')
        )
      );
      setLocalidades(localidadesUnicas);

      const somaPrecos = produtosData.produtos.reduce(
        (acc: number, produto: Produto) => acc + produto.price,
        0
      );
      setMediaPreco(somaPrecos / produtosData.produtos.length);

      analisarSentimentos(produtosData.produtos);
    }
  }, [produtosData]);

  useEffect(() => {
    if (categoriasData) {
      setCategorias(categoriasData);
    }
  }, [categoriasData]);

  useEffect(() => {
    const roleModulo = sessionStorage.getItem('roleModulo');
    if (roleModulo === 'trial') {
      setIsTrial(true);
      setLimite(10); // Se for trial, limita a 10 registros por página
    }
  }, []);

  // Função que executa ao carregar a página
  useEffect(() => {
    const initialize = async () => {
      try {
        setLoading(true);
        const selectedAccount = '7'; // Defina sua conta selecionada ou obtenha de algum lugar
        await refreshToken(selectedAccount, setLoading); // Chama a função refreshToken
        // Você pode adicionar qualquer outra lógica aqui, caso necessário
      } catch (error) {
        console.error('Erro ao atualizar o token:', error);
        toast.error('Erro ao atualizar o token');
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [selectedAccount]); // Dependência da conta selecionada

  const analisarSentimentos = async (produtosData: Produto[]) => {
    const sentiment = new Sentiment();
    let positivo = 0;
    let negativo = 0;
    let neutro = 0;

    for (const produto of produtosData) {
      try {
        const descricao = produto.title || '';
        const resultado = sentiment.analyze(descricao);

        if (resultado.score > 0) positivo++;
        else if (resultado.score < 0) negativo++;
        else neutro++;
      } catch (error) {
        console.error('Erro ao obter descrição do produto:', error);
      }
    }

    setSentimentos({ positivo, negativo, neutro });
  };

  // Extrai o parâmetro `q` da URL ao carregar a página
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const termoBusca = params.get('q');
    if (termoBusca) {
      setInputTermoBusca(termoBusca); // Define o termo de busca com o valor do parâmetro `q`
      setSearchParams((prev) => ({ ...prev, termoBusca })); // Atualiza os parâmetros de busca
      setPesquisaRealizada(true); // Define que a pesquisa foi realizada
    }
  }, [location.search]); // Executa ao carregar a página ou quando a URL muda

  const buscarProdutos = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOffset(0);
    setPaginaAtual(1);
    setVendedoresSelecionados([]);
    setSearchParams({
      termoBusca: inputTermoBusca,
      precoMinimo: inputPrecoMinimo,
      precoMaximo: inputPrecoMaximo,
      filtroAvaliacao: inputFiltroAvaliacao,
      filtroFrete: inputFiltroFrete,
      filtroDesconto: inputFiltroDesconto,
      filtroCategoria: inputFiltroCategoria,
      filtroCondicao: inputFiltroCondicao,
      filtroDisponibilidade: inputFiltroDisponibilidade,
      filtroMarca: inputFiltroMarca,
      filtroLocalidade: inputFiltroLocalidade,
      ordenacao: inputOrdenacao, // Incluindo ordenação nos parâmetros de busca
    });
    atualizarFiltrosAplicados();
    setPesquisaRealizada(true);
  };

  const proximaPagina = () => {
    if (paginaAtual < totalPaginas) {
      setOffset(offset + limite);
      setPaginaAtual(paginaAtual + 1);
    }
  };

  const paginaAnterior = () => {
    if (offset >= limite) {
      setOffset(offset - limite);
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setVendedoresSelecionados([...vendedoresSelecionados, value]);
    } else {
      setVendedoresSelecionados(
        vendedoresSelecionados.filter((vendedor) => vendedor !== value)
      );
    }
  };

  const handleSelecionarTodos = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelecionarTodos(e.target.checked);
    if (e.target.checked) {
      setProdutosSelecionados(produtosFiltrados.map((p) => p.id));
    } else {
      setProdutosSelecionados([]);
    }
  };

  const handleProdutoSelect = (id: string) => {
    if (produtosSelecionados.includes(id)) {
      setProdutosSelecionados(
        produtosSelecionados.filter((prodId) => prodId !== id)
      );
      setSelecionarTodos(false);
    } else {
      setProdutosSelecionados([...produtosSelecionados, id]);
      if (produtosSelecionados.length + 1 === produtosFiltrados.length) {
        setSelecionarTodos(true);
      }
    }
  };

  const produtosFiltrados = produtos.filter((produto: Produto) => {
    if (
      vendedoresSelecionados.length > 0 &&
      !vendedoresSelecionados.includes(
        produto.seller?.nickname || 'Não informado'
      )
    )
      return false;
    if (
      searchParams.filtroFrete === 'gratis' &&
      !produto.shipping.free_shipping
    )
      return false;
    if (searchParams.filtroDesconto && !produto.original_price) return false;
    if (
      searchParams.filtroCondicao &&
      produto.condition !== searchParams.filtroCondicao
    )
      return false;
    if (
      searchParams.filtroDisponibilidade === 'disponivel' &&
      produto.available_quantity === 0
    )
      return false;
    if (
      searchParams.filtroDisponibilidade === 'esgotado' &&
      produto.available_quantity > 0
    )
      return false;
    if (searchParams.filtroMarca) {
      const marcaProduto =
        produto.attributes?.find((attr) => attr.id === 'BRAND')?.value_name ||
        '';
      if (marcaProduto !== searchParams.filtroMarca) return false;
    }
    if (
      searchParams.filtroLocalidade &&
      produto.seller_address?.city?.name !== searchParams.filtroLocalidade
    )
      return false;
    return true;
  });

  const dadosGraficoPizza = {
    labels: vendedores,
    datasets: [
      {
        data: vendedores.map(
          (vendedor) =>
            produtos.filter(
              (produto) =>
                (produto.seller?.nickname || 'Não informado') === vendedor
            ).length
        ),
        backgroundColor: vendedores.map(
          () => '#' + Math.floor(Math.random() * 16777215).toString(16)
        ),
      },
    ],
  };

  const intervalosPreco = produtos.reduce(
    (acc: { [key: number]: number }, produto: Produto) => {
      const faixa = Math.floor(produto.price / 100) * 100;
      acc[faixa] = (acc[faixa] || 0) + 1;
      return acc;
    },
    {}
  );

  const dadosGraficoBarras = {
    labels: Object.keys(intervalosPreco).sort((a, b) => Number(a) - Number(b)),
    datasets: [
      {
        label: 'Quantidade de Produtos',
        data: Object.values(intervalosPreco),
        backgroundColor: '#007bff',
      },
    ],
  };

  const dadosGraficoSentimentos = {
    labels: ['Positivo', 'Negativo', 'Neutro'],
    datasets: [
      {
        data: [sentimentos.positivo, sentimentos.negativo, sentimentos.neutro],
        backgroundColor: ['#28a745', '#dc3545', '#ffc107'],
      },
    ],
  };

  const calcularTop5Vendedores = () => {
    const contagemVendedores = produtos.reduce(
      (acc: { [key: string]: number }, produto: Produto) => {
        const vendedor = produto.seller?.nickname || 'Não informado';
        if (!acc[vendedor]) acc[vendedor] = 0;
        acc[vendedor]++;
        return acc;
      },
      {}
    );

    const vendedoresOrdenados = Object.keys(contagemVendedores).sort(
      (a, b) => contagemVendedores[b] - contagemVendedores[a]
    );
    const top5Vendedores = vendedoresOrdenados.slice(0, 5);
    const top5Quantidades = top5Vendedores.map(
      (vendedor) => contagemVendedores[vendedor]
    );

    return {
      labels: top5Vendedores,
      datasets: [
        {
          label: 'Quantidade de Produtos',
          data: top5Quantidades,
          backgroundColor: '#17a2b8',
        },
      ],
    };
  };

  const calcularVendedoresMaiorPrecoMedio = () => {
    const vendedoresPreco = produtos.reduce(
      (
        acc: { [key: string]: { totalPreco: number; count: number } },
        produto: Produto
      ) => {
        const vendedor = produto.seller?.nickname || 'Não informado';
        if (!acc[vendedor]) acc[vendedor] = { totalPreco: 0, count: 0 };
        acc[vendedor].totalPreco += produto.price;
        acc[vendedor].count++;
        return acc;
      },
      {}
    );

    const vendedoresPrecoMedio = Object.keys(vendedoresPreco).map(
      (vendedor) => ({
        vendedor,
        precoMedio:
          vendedoresPreco[vendedor].totalPreco /
          vendedoresPreco[vendedor].count,
      })
    );

    const vendedoresOrdenados = vendedoresPrecoMedio.sort(
      (a, b) => b.precoMedio - a.precoMedio
    );
    const top5Vendedores = vendedoresOrdenados.slice(0, 5);

    return {
      labels: top5Vendedores.map((item) => item.vendedor),
      datasets: [
        {
          label: 'Preço Médio',
          data: top5Vendedores.map((item) => item.precoMedio),
          backgroundColor: '#ffc107',
        },
      ],
    };
  };

  const calcularVendedoresMenorPrecoMedio = () => {
    const vendedoresPreco = produtos.reduce(
      (
        acc: { [key: string]: { totalPreco: number; count: number } },
        produto: Produto
      ) => {
        const vendedor = produto.seller?.nickname || 'Não informado';
        if (!acc[vendedor]) acc[vendedor] = { totalPreco: 0, count: 0 };
        acc[vendedor].totalPreco += produto.price;
        acc[vendedor].count++;
        return acc;
      },
      {}
    );

    const vendedoresPrecoMedio = Object.keys(vendedoresPreco).map(
      (vendedor) => ({
        vendedor,
        precoMedio:
          vendedoresPreco[vendedor].totalPreco /
          vendedoresPreco[vendedor].count,
      })
    );

    const vendedoresOrdenados = vendedoresPrecoMedio.sort(
      (a, b) => a.precoMedio - b.precoMedio
    );
    const top5Vendedores = vendedoresOrdenados.slice(0, 5);

    return {
      labels: top5Vendedores.map((item) => item.vendedor),
      datasets: [
        {
          label: 'Preço Médio',
          data: top5Vendedores.map((item) => item.precoMedio),
          backgroundColor: '#dc3545',
        },
      ],
    };
  };

  const atualizarFiltrosAplicados = () => {
    const filtros: string[] = [];
    if (searchParams.precoMinimo)
      filtros.push(`Preço min: R$ ${searchParams.precoMinimo}`);
    if (searchParams.precoMaximo)
      filtros.push(`Preço max: R$ ${searchParams.precoMaximo}`);
    if (searchParams.filtroAvaliacao)
      filtros.push(`Avaliação: ${searchParams.filtroAvaliacao}`);
    if (searchParams.filtroFrete)
      filtros.push(`Frete: ${searchParams.filtroFrete}`);
    if (searchParams.filtroDesconto) filtros.push('Com desconto');
    if (searchParams.filtroCategoria) {
      const categoria = categorias.find(
        (cat) => cat.id === searchParams.filtroCategoria
      );
      if (categoria) filtros.push(`Categoria: ${categoria.name}`);
    }
    if (searchParams.filtroCondicao)
      filtros.push(
        `Condição: ${searchParams.filtroCondicao === 'new' ? 'Novo' : 'Usado'}`
      );
    if (searchParams.filtroDisponibilidade)
      filtros.push(
        `Disponibilidade: ${
          searchParams.filtroDisponibilidade === 'disponivel'
            ? 'Disponível'
            : 'Esgotado'
        }`
      );
    if (searchParams.filtroMarca)
      filtros.push(`Marca: ${searchParams.filtroMarca}`);
    if (searchParams.filtroLocalidade)
      filtros.push(`Localidade: ${searchParams.filtroLocalidade}`);
    if (searchParams.ordenacao)
      filtros.push(
        `Ordenação: ${
          searchParams.ordenacao === 'price_asc'
            ? 'Menor Preço'
            : searchParams.ordenacao === 'price_desc'
            ? 'Maior Preço'
            : 'Relevância'
        }`
      );
    setFiltrosAplicados(filtros);
  };

  const removerFiltro = (filtro: string) => {
    if (filtro.startsWith('Preço min')) setInputPrecoMinimo('');
    if (filtro.startsWith('Preço max')) setInputPrecoMaximo('');
    if (filtro.startsWith('Avaliação')) setInputFiltroAvaliacao('');
    if (filtro.startsWith('Frete')) setInputFiltroFrete('');
    if (filtro === 'Com desconto') setInputFiltroDesconto(false);
    if (filtro.startsWith('Categoria')) setInputFiltroCategoria('');
    if (filtro.startsWith('Condição')) setInputFiltroCondicao('');
    if (filtro.startsWith('Disponibilidade')) setInputFiltroDisponibilidade('');
    if (filtro.startsWith('Marca')) setInputFiltroMarca('');
    if (filtro.startsWith('Localidade')) setInputFiltroLocalidade('');
    if (filtro.startsWith('Ordenação')) setInputOrdenacao('');
    setFiltrosAplicados(filtrosAplicados.filter((f) => f !== filtro));
    refetchProdutos();
  };

  const limparFiltros = () => {
    setInputTermoBusca('');
    setInputPrecoMinimo('');
    setInputPrecoMaximo('');
    setInputFiltroAvaliacao('');
    setInputFiltroFrete('');
    setInputFiltroDesconto(false);
    setInputFiltroCategoria('');
    setVendedoresSelecionados([]);
    setInputFiltroCondicao('');
    setInputFiltroDisponibilidade('');
    setInputFiltroMarca('');
    setInputFiltroLocalidade('');
    setInputOrdenacao('');
    setFiltrosAplicados([]);
    setSearchParams({
      termoBusca: '',
      precoMinimo: '',
      precoMaximo: '',
      filtroAvaliacao: '',
      filtroFrete: '',
      filtroDesconto: false,
      filtroCategoria: '',
      filtroCondicao: '',
      filtroDisponibilidade: '',
      filtroMarca: '',
      filtroLocalidade: '',
      ordenacao: '',
    });
    refetchProdutos();
  };

  const exportToExcel = async () => {
    const produtosParaExportar =
      produtosSelecionados.length > 0
        ? produtosFiltrados.filter((produto) =>
            produtosSelecionados.includes(produto.id)
          )
        : produtosFiltrados;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Produtos');

    worksheet.columns = [
      { header: 'Título', key: 'title', width: 30 },
      { header: 'Preço', key: 'price', width: 15 },
      { header: 'Vendedor', key: 'seller', width: 25 },
      { header: 'Condição', key: 'condition', width: 10 },
      { header: 'Quantidade Disponível', key: 'available_quantity', width: 20 },
      { header: 'Quantidade Vendida', key: 'sold_quantity', width: 20 },
      { header: 'Frete Grátis', key: 'free_shipping', width: 15 },
      { header: 'Marca', key: 'brand', width: 15 },
      { header: 'Localidade', key: 'location', width: 25 },
      { header: 'Link', key: 'permalink', width: 50 },
    ];

    produtosParaExportar.forEach((produto) => {
      worksheet.addRow({
        title: produto.title,
        price: produto.price,
        seller: produto.seller?.nickname || 'Não informado',
        condition: produto.condition === 'new' ? 'Novo' : 'Usado',
        available_quantity: produto.available_quantity,
        sold_quantity: produto.sold_quantity,
        free_shipping: produto.shipping.free_shipping ? 'Sim' : 'Não',
        brand:
          produto.attributes?.find((attr) => attr.id === 'BRAND')?.value_name ||
          'N/A',
        location: produto.seller_address?.city?.name || 'N/A',
        permalink: produto.permalink,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'produtos_mercadolivre.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success('Exportação para Excel realizada com sucesso!');
  };

  const adicionarComparacao = (produto: Produto) => {
    if (produtosComparacao.length >= 6) {
      toast.warn('Você só pode comparar até 6 produtos.');
      return;
    }
    setProdutosComparacao([...produtosComparacao, produto]);
  };

  const removerComparacao = (produtoId: string) => {
    setProdutosComparacao(
      produtosComparacao.filter((produto) => produto.id !== produtoId)
    );
  };

  const abrirModalComparacao = () => {
    setShowComparacaoModal(true);
  };

  const fecharModalComparacao = () => {
    setShowComparacaoModal(false);
  };

  return (
    <div className="container-fluid">
      <FiltroBusca
        termoBusca={inputTermoBusca}
        setTermoBusca={setInputTermoBusca}
        precoMinimo={inputPrecoMinimo}
        setPrecoMinimo={setInputPrecoMinimo}
        precoMaximo={inputPrecoMaximo}
        setPrecoMaximo={setInputPrecoMaximo}
        filtroAvaliacao={inputFiltroAvaliacao}
        setFiltroAvaliacao={setInputFiltroAvaliacao}
        filtroFrete={inputFiltroFrete}
        setFiltroFrete={setInputFiltroFrete}
        filtroCategoria={inputFiltroCategoria}
        setFiltroCategoria={setInputFiltroCategoria}
        categorias={categorias}
        filtroCondicao={inputFiltroCondicao}
        setFiltroCondicao={setInputFiltroCondicao}
        filtroMarca={inputFiltroMarca}
        setFiltroMarca={setInputFiltroMarca}
        marcas={marcas}
        filtroLocalidade={inputFiltroLocalidade}
        setFiltroLocalidade={setInputFiltroLocalidade}
        localidades={localidades}
        filtroDesconto={inputFiltroDesconto}
        setFiltroDesconto={setInputFiltroDesconto}
        buscarProdutos={buscarProdutos}
        limparFiltros={limparFiltros}
        ordenacao={inputOrdenacao}
        setOrdenacao={setInputOrdenacao}
      />

      {filtrosAplicados.length > 0 && (
        <div className="my-3">
          <strong>Filtros aplicados:</strong>
          {filtrosAplicados.map((filtro, index) => (
            <Badge bg="primary" className="me-2" key={index}>
              {filtro}{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => removerFiltro(filtro)}
              >
                ×
              </span>
            </Badge>
          ))}
        </div>
      )}

      {isLoading ? (
        <div className="text-center my-5">
          <Spinner animation="border" variant="primary" />
          <div>Carregando...</div>
        </div>
      ) : pesquisaRealizada && produtos.length > 0 ? (
        <>
          {/* Componentes de análise */}
          <Analises
            calcularTop5Vendedores={calcularTop5Vendedores}
            calcularVendedoresMaiorPrecoMedio={
              calcularVendedoresMaiorPrecoMedio
            }
            calcularVendedoresMenorPrecoMedio={
              calcularVendedoresMenorPrecoMedio
            }
            dadosGraficoPizza={dadosGraficoPizza}
            dadosGraficoBarras={dadosGraficoBarras}
            dadosGraficoSentimentos={dadosGraficoSentimentos}
            mediaPreco={mediaPreco}
          />

          <FiltroVendedores
            vendedores={vendedores}
            vendedoresSelecionados={vendedoresSelecionados}
            handleCheckboxChange={handleCheckboxChange}
          />

          {/* Resultados da busca */}
          <ResultadosBusca
            produtosFiltrados={produtosFiltrados}
            limite={limite}
            setLimite={setLimite}
            exportToExcel={exportToExcel}
            abrirModalComparacao={abrirModalComparacao}
            produtosComparacao={produtosComparacao}
            selecionarTodos={selecionarTodos}
            handleSelecionarTodos={handleSelecionarTodos}
            produtosSelecionados={produtosSelecionados}
            handleProdutoSelect={handleProdutoSelect}
            removerComparacao={removerComparacao}
            adicionarComparacao={adicionarComparacao}
            paginaAnterior={paginaAnterior}
            proximaPagina={proximaPagina}
            offset={offset}
            totalPaginas={totalPaginas}
            paginaAtual={paginaAtual}
            isTrial={isTrial} // Passando a variável para desabilitar o botão de exportar
          />
        </>
      ) : pesquisaRealizada && produtos.length === 0 ? (
        <Alert variant="warning" className="mt-4">
          Nenhum produto encontrado. Tente ajustar os filtros ou a busca.
        </Alert>
      ) : null}

      <ComparisonModal
        show={showComparacaoModal}
        onHide={fecharModalComparacao}
        produtosComparacao={produtosComparacao}
      />
    </div>
  );
};

export default BuscaPreco;
