// ProductSearchLink.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './ProductSearchLink.css';
import { Button } from 'react-bootstrap';

interface ProductSearchLinkProps {
  produto: string;
}

const ProductSearchLink: React.FC<ProductSearchLinkProps> = ({ produto }) => {
  const handleSearchClick = () => {
    window.open(`/pesquisaMercado?q=${encodeURIComponent(produto)}`, '_blank');
  };

  return (
    <Button
      onClick={handleSearchClick}
      className="mx-2"
      size="sm"
      variant="dark"
    >
      Buscar Preço
    </Button>
  );
};

ProductSearchLink.propTypes = {
  produto: PropTypes.string.isRequired,
};

export default ProductSearchLink;
