import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import ReactPlayer from 'react-player';

interface HelpVideoModalProps {
  videoUrl: string;
}

const HelpVideoModal: React.FC<HelpVideoModalProps> = ({ videoUrl }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <FaQuestionCircle
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          fontSize: '24px',
          color: '#007bff',
          transition: 'transform 0.2s ease',
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      />
      <strong
        onClick={handleShow}
        style={{
          cursor: 'pointer',
          color: '#007bff',
          textDecoration: 'underline',
        }}
      >
        Assistir vídeo de ajuda
      </strong>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ajuda</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <ReactPlayer
            url={videoUrl}
            controls
            width="100%"
            height="100%"
            playing={show}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HelpVideoModal;
