import api from '../../services/api';

interface EstoqueMovimentoParams {
  produtoId: number;
  quantidade: number;
  tipo: 'Entrada' | 'Saída' | 'Ajuste';
  meliContaId?: number; // Pode ser opcional, padrão para estoque local
  usuario: string; // Nome ou ID do usuário que está realizando a operação
}

interface EstoqueMovimentoResult {
  isOk: boolean;
  message?: string;
}

export const movimentarEstoque = async ({
  produtoId,
  quantidade,
  tipo,
  meliContaId = 0,
  usuario,
}: EstoqueMovimentoParams): Promise<EstoqueMovimentoResult> => {
  try {
    // Busca o estoque existente para o produto e conta específica (0 para local)
    const response = await api.get(
      `${import.meta.env.VITE_APP_API_URL}/estoques`,
      {
        params: {
          produto_id: produtoId,
          meli_conta_id: meliContaId,
        },
      }
    );

    const estoqueExistente = response.data.data.find(
      (e: any) => e.produto_id === produtoId && e.meli_conta_id === meliContaId
    );

    let novaQuantidade;
    if (tipo === 'Entrada') {
      novaQuantidade = estoqueExistente
        ? estoqueExistente.quantidade + quantidade
        : quantidade;
    } else if (tipo === 'Saída') {
      novaQuantidade = estoqueExistente
        ? estoqueExistente.quantidade - quantidade
        : -1;
      if (novaQuantidade < 0) {
        return {
          isOk: false,
          message: `Quantidade insuficiente no estoque. Estoque Atual Qtde: ${
            estoqueExistente ? estoqueExistente.quantidade : 0
          }`,
        };
      }
    } else if (tipo === 'Ajuste') {
      novaQuantidade = quantidade;
    }

    if (estoqueExistente) {
      // Atualiza a quantidade de estoque
      await api.put(
        `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueExistente.id}`,
        {
          produto_id: produtoId,
          quantidade: novaQuantidade,
          meli_conta_id: meliContaId,
        }
      );
    } else {
      // Cria um novo registro de estoque se não existir
      await api.post(`${import.meta.env.VITE_APP_API_URL}/estoques`, {
        produto_id: produtoId,
        quantidade: novaQuantidade,
        meli_conta_id: meliContaId,
      });
    }

    // Registra o movimento de estoque, incluindo ajustes
    await api.post(`${import.meta.env.VITE_APP_API_URL}/movimento-estoques`, {
      estoque_id: estoqueExistente ? estoqueExistente.id : null,
      produto_id: produtoId,
      quantidade,
      tipo,
      usuario,
      meli_conta_id: meliContaId,
    });

    return { isOk: true, message: `${tipo} de estoque realizada com sucesso!` };
  } catch (error) {
    return {
      isOk: false,
      message: 'Erro ao realizar a movimentação de estoque.',
    };
  }
};
