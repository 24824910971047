import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Form,
  Button,
  Card,
  Alert,
  Table,
  Pagination,
  Spinner,
  Row,
  Col,
} from 'react-bootstrap';
import CurrencyInput, { convertToNumber } from '../../utils/CurrencyInput';
import ExcelJS from 'exceljs';

interface Produto {
  id: string;
  title: string;
  price: number;
  permalink: string;
  thumbnail: string;
  seller?: {
    nickname: string;
  };
  condition: string;
  available_quantity: number;
  shipping: {
    free_shipping: boolean;
  };
  attributes?: { id: string; value_name: string }[];
  seller_address?: {
    city?: { name: string };
  };
}

const CalculadoraMargemLucro: React.FC = () => {
  const [produtoBusca, setProdutoBusca] = useState<string>('');
  const [precoVenda, setPrecoVenda] = useState<string>('R$ 0,00');
  const [custoProduto, setCustoProduto] = useState<string>('R$ 0,00');
  const [impostos, setImpostos] = useState<string>('R$ 0,00');
  const [frete, setFrete] = useState<string>('R$ 0,00');
  const [despesasOperacionais, setDespesasOperacionais] =
    useState<string>('R$ 0,00');
  const [margemLucro, setMargemLucro] = useState<number | null>(null);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState<string[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [precoMedio, setPrecoMedio] = useState<number>(0);
  const [limite, setLimite] = useState<number>(50);
  const [paginaAtual, setPaginaAtual] = useState<number>(1);
  const [totalResultados, setTotalResultados] = useState<number>(0);
  const [ordenacao, setOrdenacao] = useState<string>('');

  useEffect(() => {
    calcularMargemLucro();
  }, [
    produtos,
    precoVenda,
    custoProduto,
    impostos,
    frete,
    despesasOperacionais,
  ]);

  const fetchProdutos = async (limitVal: number, offsetVal: number) => {
    if (!produtoBusca.trim()) {
      alert('Por favor, digite o título do produto.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(
        'https://api.mercadolibre.com/sites/MLB/search',
        {
          params: {
            q: produtoBusca,
            limit: limitVal,
            offset: offsetVal,
            sort: ordenacao || undefined,
          },
        }
      );
      if (response.status === 200) {
        const produtosData = response.data.results;
        setProdutos(produtosData);
        calcularPrecoMedio(produtosData);
        setTotalResultados(response.data.paging.total);
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const calcularPrecoMedio = (produtosData: Produto[]) => {
    if (produtosData.length === 0) {
      setPrecoMedio(0);
      return;
    }
    const somaPrecos = produtosData.reduce(
      (acc: number, produto: Produto) => acc + produto.price,
      0
    );
    setPrecoMedio(somaPrecos / produtosData.length);
  };

  const calcularMargemLucro = () => {
    const precoVendaNumber = convertToNumber(precoVenda);
    const custoProdutoNumber = convertToNumber(custoProduto);
    const impostosNumber = convertToNumber(impostos);
    const freteNumber = convertToNumber(frete);
    const despesasOperacionaisNumber = convertToNumber(despesasOperacionais);

    if (precoVendaNumber <= 0 || isNaN(precoVendaNumber)) {
      setMargemLucro(null);
      return;
    }

    const totalCustos =
      custoProdutoNumber +
      impostosNumber +
      freteNumber +
      despesasOperacionaisNumber;
    const lucroBruto = precoVendaNumber - totalCustos;

    if (isNaN(lucroBruto) || isNaN(precoVendaNumber)) {
      setMargemLucro(null);
      return;
    }

    const margem = (lucroBruto / precoVendaNumber) * 100;
    setMargemLucro(margem);
  };

  const aplicarPrecoMedio = () => {
    setPrecoVenda(`R$ ${precoMedio.toFixed(2).replace('.', ',')}`);
  };

  const handleProdutoSelect = (produtoId: string) => {
    if (produtosSelecionados.includes(produtoId)) {
      setProdutosSelecionados(
        produtosSelecionados.filter((id) => id !== produtoId)
      );
    } else {
      setProdutosSelecionados([...produtosSelecionados, produtoId]);
    }
  };

  const excluirProduto = (produtoId: string) => {
    setProdutos(produtos.filter((produto) => produto.id !== produtoId));
  };

  const exportarParaExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Produtos');

    worksheet.columns = [
      { header: 'Título', key: 'title', width: 30 },
      { header: 'Preço', key: 'price', width: 15 },
      { header: 'Link', key: 'permalink', width: 50 },
    ];

    const produtosParaExportar = produtosSelecionados.length
      ? produtos.filter((produto) => produtosSelecionados.includes(produto.id))
      : produtos;

    produtosParaExportar.forEach((produto) => {
      worksheet.addRow({
        title: produto.title,
        price: `R$ ${produto.price.toFixed(2)}`,
        permalink: produto.permalink,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'produtos_mercadolivre.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePaginationChange = (newPage: number) => {
    const newOffset = (newPage - 1) * limite;
    setPaginaAtual(newPage);
    fetchProdutos(limite, newOffset);
  };

  const handleBuscarProdutos = () => {
    setPaginaAtual(1);
    fetchProdutos(limite, 0);
  };

  // Calcular total de páginas
  const totalPaginas = Math.ceil(totalResultados / limite);

  useEffect(() => {
    // Quando 'ordenacao' muda, resetar para a primeira página e buscar produtos
    if (produtoBusca.trim()) {
      setPaginaAtual(1);
      fetchProdutos(limite, 0);
    }
  }, [ordenacao]);

  return (
    <div className="container-fluid">
      <div className="bg-white py-3">
        <Card>
          <Card.Header>Calculadora de Margem de Lucro</Card.Header>
          <Card.Body>
            <Form>
              <Row className="mb-3">
                <Col xs={12} md={8}>
                  <Form.Group controlId="produtoBusca">
                    <Form.Label>Título do Produto</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o título do produto"
                      value={produtoBusca}
                      onChange={(e) => setProdutoBusca(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleBuscarProdutos();
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-end">
                  <Button
                    variant="primary"
                    onClick={handleBuscarProdutos}
                    disabled={isLoading}
                    className="w-100"
                  >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      'Buscar Produtos'
                    )}
                  </Button>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={4}>
                  <Form.Group controlId="precoVenda">
                    <Form.Label>Preço de Venda</Form.Label>
                    <CurrencyInput
                      value={precoVenda}
                      onChange={setPrecoVenda}
                    />
                    <Button
                      variant="link"
                      onClick={aplicarPrecoMedio}
                      disabled={isLoading || precoMedio === 0}
                    >
                      Usar preço médio dos produtos (
                      {`R$ ${precoMedio.toFixed(2)}`})
                    </Button>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="custoProduto">
                    <Form.Label>Custo do Produto</Form.Label>
                    <CurrencyInput
                      value={custoProduto}
                      onChange={setCustoProduto}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="impostos">
                    <Form.Label>Impostos</Form.Label>
                    <CurrencyInput value={impostos} onChange={setImpostos} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={4}>
                  <Form.Group controlId="frete">
                    <Form.Label>Frete</Form.Label>
                    <CurrencyInput value={frete} onChange={setFrete} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="despesasOperacionais">
                    <Form.Label>Despesas Operacionais</Form.Label>
                    <CurrencyInput
                      value={despesasOperacionais}
                      onChange={setDespesasOperacionais}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="ordenacao">
                    <Form.Label>Ordenar por</Form.Label>
                    <Form.Select
                      value={ordenacao}
                      onChange={(e) => setOrdenacao(e.target.value)}
                    >
                      <option value="">Relevância</option>
                      <option value="price_asc">Menor Preço</option>
                      <option value="price_desc">Maior Preço</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {margemLucro !== null && !isNaN(margemLucro) && (
                <Alert
                  variant={margemLucro >= 0 ? 'success' : 'danger'}
                  className="mt-3"
                >
                  Margem de Lucro: {margemLucro.toFixed(2)}%
                </Alert>
              )}
            </Form>
          </Card.Body>
        </Card>
      </div>

      {produtos.length > 0 && (
        <>
          <h3 className="mt-5">Produtos Encontrados</h3>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <Form.Group
              controlId="limite"
              className="d-flex align-items-center"
            >
              <Form.Label className="mb-0 me-2">Itens por página:</Form.Label>
              <Form.Select
                value={limite}
                onChange={(e) => {
                  const newLimit = Number(e.target.value);
                  setLimite(newLimit);
                  setPaginaAtual(1);
                  fetchProdutos(newLimit, 0);
                }}
                style={{ width: '100px' }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </Form.Select>
            </Form.Group>
            <Button onClick={exportarParaExcel}>Exportar para Excel</Button>
          </div>
          <Table striped bordered hover responsive className="mt-3">
            <thead>
              <tr>
                <th>Selecionar</th>
                <th>Imagem</th>
                <th>Produto</th>
                <th>Preço Vendido</th>
                <th>Vendedor</th>
                <th>Condição</th>
                <th>Disponibilidade</th>
                <th>Frete Grátis</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {produtos.map((produto) => (
                <tr key={produto.id}>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={produtosSelecionados.includes(produto.id)}
                      onChange={() => handleProdutoSelect(produto.id)}
                    />
                  </td>
                  <td className="text-center">
                    <img
                      src={produto.thumbnail}
                      alt={produto.title}
                      width="40"
                      height="40"
                    />
                  </td>
                  <td>{produto.title}</td>
                  <td>{`R$ ${produto.price.toFixed(2)}`}</td>
                  <td>{produto.seller?.nickname || ''}</td>
                  <td>{produto.condition === 'new' ? 'Novo' : 'Usado'}</td>
                  <td>
                    {produto.available_quantity > 0 ? 'Disponível' : 'Esgotado'}
                  </td>
                  <td>{produto.shipping.free_shipping ? 'Sim' : 'Não'}</td>
                  <td>
                    <a
                      href={produto.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-block mb-1"
                    >
                      Ver Anúncio
                    </a>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => excluirProduto(produto.id)}
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-center align-items-center mt-3">
            <Pagination>
              <Pagination.Prev
                onClick={() => handlePaginationChange(paginaAtual - 1)}
                disabled={paginaAtual === 1}
              >
                Anterior
              </Pagination.Prev>
              <Pagination.Item
                active
              >{`Página ${paginaAtual} de ${totalPaginas}`}</Pagination.Item>
              <Pagination.Next
                onClick={() => handlePaginationChange(paginaAtual + 1)}
                disabled={paginaAtual === totalPaginas}
              >
                Próximo
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      )}
    </div>
  );
};

export default CalculadoraMargemLucro;
