import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Bar, Pie } from 'react-chartjs-2';

interface AnalisesProps {
  calcularTop5Vendedores: () => any;
  calcularVendedoresMaiorPrecoMedio: () => any;
  calcularVendedoresMenorPrecoMedio: () => any;
  dadosGraficoPizza: any;
  dadosGraficoBarras: any;
  dadosGraficoSentimentos: any;
  mediaPreco: number;
}

const Analises: React.FC<AnalisesProps> = ({
  calcularTop5Vendedores,
  calcularVendedoresMaiorPrecoMedio,
  calcularVendedoresMenorPrecoMedio,
  dadosGraficoPizza,
  dadosGraficoBarras,
  dadosGraficoSentimentos,
  mediaPreco,
}) => {
  const graficoOptions = {
    maintainAspectRatio: false, // Mantém a proporção personalizada
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as 'bottom', // Ajusta a posição da legenda
      },
    },
  };
  return (
    <>
      <h2 className="mt-5">Análises</h2>
      <hr />
      <Row className="my-4">
        <Col md={6} lg={4}>
          <h5>Top 5 Vendedores (Quantidade de Produtos)</h5>
          <hr />

          <Bar data={calcularTop5Vendedores()} />
        </Col>
        <Col md={6} lg={4}>
          <h5>Vendedores com Maior Preço Médio</h5>
          <hr />

          <Bar data={calcularVendedoresMaiorPrecoMedio()} />
        </Col>
        <Col md={6} lg={4}>
          <h5>Vendedores com Menor Preço Médio</h5>
          <hr />

          <Bar data={calcularVendedoresMenorPrecoMedio()} />
        </Col>
      </Row>
      <hr />
      <Row className="my-4">
        <Col md={6} lg={4}>
          <h5>Distribuição de Vendedores</h5>
          <hr />

          <div style={{ height: '580px' }}>
            <Pie data={dadosGraficoPizza} />
          </div>
        </Col>
        <Col md={6} lg={4}>
          <h5>Faixa de Preços</h5>
          <hr />

          <Bar data={dadosGraficoBarras} />
        </Col>
        <Col md={6} lg={4}>
          <h5>Análise de Sentimentos</h5>
          <hr />
          <p>
            A análise de sentimentos ajuda a entender o tom das descrições dos
            produtos no Mercado Livre, classificando-as como positivas,
            negativas ou neutras. As cores no gráfico representam:
          </p>
          <ul>
            <li>
              <strong style={{ color: '#28a745' }}>Positivo:</strong> Descrições
              com termos que indicam satisfação e otimismo.
            </li>
            <li>
              <strong style={{ color: '#dc3545' }}>Negativo:</strong> Descrições
              que possuem palavras associadas a problemas ou insatisfação.
            </li>
            <li>
              <strong style={{ color: '#ffc107' }}>Neutro:</strong> Descrições
              sem polarização clara, sendo mais informativas do que emocionais.
            </li>
          </ul>
          <div style={{ height: '350px' }}>
            <Pie data={dadosGraficoSentimentos} options={graficoOptions} />
          </div>{' '}
        </Col>
      </Row>
      <hr />

      <div style={{ textAlign: 'center' }}>
        <h3>Média de Preço: R$ {mediaPreco.toFixed(2)}</h3>
        <hr />
      </div>
    </>
  );
};

export default Analises;
