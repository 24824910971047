import React from 'react';
import {
  Table,
  Button,
  Form,
  Pagination,
  OverlayTrigger,
  Tooltip,
  Alert,
} from 'react-bootstrap';
import { Produto } from './type';
import { formatCurrency } from '../../utils';
import './index.css';

interface ResultadosBuscaProps {
  produtosFiltrados: Produto[];
  limite: number;
  setLimite: (limite: number) => void;
  exportToExcel: (produtosParaExportar: Produto[]) => void; // Passar os produtos para exportação
  abrirModalComparacao: () => void;
  produtosComparacao: Produto[];
  selecionarTodos: boolean;
  handleSelecionarTodos: (e: React.ChangeEvent<HTMLInputElement>) => void;
  produtosSelecionados: string[];
  handleProdutoSelect: (id: string) => void;
  removerComparacao: (id: string) => void;
  adicionarComparacao: (produto: Produto) => void;
  paginaAnterior: () => void;
  proximaPagina: () => void;
  offset: number;
  totalPaginas: number;
  paginaAtual: number;
  isTrial: boolean; // Adding isTrial to the props
}

const ResultadosBusca: React.FC<ResultadosBuscaProps> = ({
  produtosFiltrados,
  limite,
  setLimite,
  exportToExcel,
  abrirModalComparacao,
  produtosComparacao,
  selecionarTodos,
  handleSelecionarTodos,
  produtosSelecionados,
  handleProdutoSelect,
  removerComparacao,
  adicionarComparacao,
  paginaAnterior,
  proximaPagina,
  offset,
  totalPaginas,
  paginaAtual,
  isTrial, // Adding isTrial to the props
}) => {
  const handleExport = () => {
    if (produtosSelecionados.length > 0) {
      // Filtra os produtos que foram selecionados
      const produtosParaExportar = produtosFiltrados.filter((produto) =>
        produtosSelecionados.includes(produto.id)
      );
      exportToExcel(produtosParaExportar);
    } else {
      // Se nenhum produto for selecionado, exporta todos os produtos filtrados
      exportToExcel(produtosFiltrados);
    }
  };

  const getListingTypeName = (listingTypeId: any) => {
    switch (listingTypeId) {
      case 'gold_pro':
        return 'Anúncio Profissional';
      case 'gold_special':
        return 'Anúncio Premium';
      case 'silver':
        return 'Anúncio Prata';
      case 'bronze':
        return 'Anúncio Bronze';
      case 'free':
        return 'Anúncio Gratuito';
      default:
        return 'Tipo de Anúncio Desconhecido';
    }
  };

  return (
    <>
      {isTrial && (
        <Alert variant="warning" className="mt-3">
          <strong>Atenção:</strong> Você está usando o plano{' '}
          <strong>trial</strong>. O limite de resultados é 10 e algumas
          funcionalidades, como a exportação para Excel, estão desabilitadas.
          Faça o upgrade para liberar todas as funcionalidades.
        </Alert>
      )}
      {produtosFiltrados.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
            <h2 className="mobile-center-text">Resultados da Busca</h2>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <Form.Label className="mb-0">Itens por página:</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={
                  isTrial ? (
                    <Tooltip id="tooltip-trial">
                      Este recurso não está disponível na versão de teste.
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <Form.Select
                  disabled={isTrial} // Desabilita a opção de mudar o limite no modo trial
                  value={limite}
                  onChange={(e) => setLimite(Number(e.target.value))}
                  className="mobile-full-width"
                  style={{ width: '100px', marginTop: '10px' }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Select>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  isTrial ? (
                    <Tooltip id="tooltip-trial">
                      Este recurso não está disponível na versão de teste.
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <span className="d-inline-block mobile-full-width">
                  <Button
                    onClick={handleExport}
                    disabled={isTrial}
                    className="mobile-full-width"
                    style={{ pointerEvents: isTrial ? 'none' : 'auto' }}
                  >
                    Exportar para Excel
                  </Button>
                </span>
              </OverlayTrigger>
              <Button
                variant="success"
                onClick={abrirModalComparacao}
                disabled={produtosComparacao.length < 2}
                className="mobile-full-width"
              >
                Comparar Produtos ({produtosComparacao.length})
              </Button>
            </div>
          </div>

          <Table striped bordered hover className="table">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    checked={selecionarTodos}
                    onChange={handleSelecionarTodos}
                    style={{ transform: 'scale(1.5)' }}
                  />
                </th>
                <th></th>
                <th className="table-col-produto">Produto</th>
                <th>Preço Vendido</th>
                <th>Preço Original</th>
                <th>Vendedor</th>
                <th className="hide-on-notebook">Condição</th>
                <th className="hide-on-notebook">Disponibilidade</th>
                <th className="hide-on-notebook">Frete Grátis</th>
                <th>Marca</th>
                <th>Localidade</th>
              </tr>
            </thead>
            <tbody>
              {produtosFiltrados.map((produto) => (
                <tr key={produto.id}>
                  <td data-label="Selecionar">
                    <Form.Check
                      type="checkbox"
                      checked={produtosSelecionados.includes(produto.id)}
                      onChange={() => handleProdutoSelect(produto.id)}
                    />
                  </td>
                  <td data-label="Imagem">
                    <img
                      src={produto.thumbnail}
                      alt={produto.title}
                      width="50"
                      height="50"
                    />
                  </td>
                  <td data-label="Produto">
                    {produto.title}
                    <br />
                    <a
                      href={produto.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver Anúncio
                    </a>
                    {produtosComparacao.some((p) => p.id === produto.id) ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => removerComparacao(produto.id)}
                        className="mx-2"
                      >
                        Remover Comparação
                      </Button>
                    ) : (
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => adicionarComparacao(produto)}
                        className="mx-2"
                      >
                        Adicionar à Comparação
                      </Button>
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-quantidade-${produto.id}`}>
                          Essa propriedade representa a quantidade atual de um
                          produto disponível em estoque.{' '}
                        </Tooltip>
                      }
                    >
                      <label
                        className="info mx-2"
                        style={{ fontWeight: 'bold' }}
                      >
                        Quantidade Atual : {produto.available_quantity}
                      </label>
                    </OverlayTrigger>
                  </td>
                  <td data-label="Preço Vendido">
                    {formatCurrency(produto?.price)}
                  </td>
                  <td data-label="Preço Original">
                    {produto?.original_price
                      ? formatCurrency(produto.original_price)
                      : 'N/A'}
                  </td>
                  <td data-label="Vendedor">
                    {produto.seller?.nickname || ''}
                    <br/>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`Tipo de Listagem-${produto.id}`}>
                          Tipos de listagem, como anúncios gratuitos, prata,
                          ouro, profissional e premium.
                        </Tooltip>
                      }
                    >
                      <label className="info">
                        {getListingTypeName(produto.listing_type_id)}
                      </label>
                    </OverlayTrigger>
                  </td>
                  <td data-label="Condição" className="hide-on-notebook">
                    {produto.condition === 'new' ? 'Novo' : 'Usado'}
                  </td>
                  <td data-label="Disponibilidade" className="hide-on-notebook">
                    {produto.available_quantity > 0 ? 'Disponível' : 'Esgotado'}
                  </td>
                  <td data-label="Frete Grátis" className="hide-on-notebook">
                    {produto.shipping.free_shipping ? 'Sim' : 'Não'}
                  </td>
                  <td data-label="Marca">
                    {produto.attributes?.find((attr) => attr.id === 'BRAND')
                      ?.value_name || 'N/A'}
                  </td>
                  <td data-label="Localidade">
                    {produto.seller_address?.city?.name || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div>Total de resultados: {produtosFiltrados.length}</div>
        </>
      ) : (
        <div className="mt-4 text-center">
          <h6 className="text-muted">
            Nenhum resultado encontrado nesta página.
          </h6>
          <p className="text-muted">
            Tente ajustar os filtros de busca ou verifique se digitou
            corretamente.
          </p>
          <img
            src="/logoheader.png"
            alt="Nenhum resultado encontrado"
            style={{ width: '150px', marginTop: '20px' }}
          />
        </div>
      )}

      {/* Botões de navegação exibidos independentemente de ter produtos */}
      <Pagination className="mt-3">
        <OverlayTrigger
          placement="top"
          overlay={
            isTrial ? (
              <Tooltip id="tooltip-trial-prev">
                Este recurso não está disponível na versão de teste.
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Pagination.Prev
              onClick={paginaAnterior}
              disabled={offset === 0 || isTrial}
              style={{ pointerEvents: isTrial ? 'none' : 'auto' }}
            >
              Anterior
            </Pagination.Prev>
          </span>
        </OverlayTrigger>

        <Pagination.Item active>
          Página {paginaAtual} de {totalPaginas}
        </Pagination.Item>

        <OverlayTrigger
          placement="top"
          overlay={
            isTrial ? (
              <Tooltip id="tooltip-trial-next">
                Este recurso não está disponível na versão de teste.
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Pagination.Next
              disabled={paginaAtual === totalPaginas || isTrial}
              onClick={proximaPagina}
              style={{ pointerEvents: isTrial ? 'none' : 'auto' }}
            >
              Próximo
            </Pagination.Next>
          </span>
        </OverlayTrigger>
      </Pagination>
    </>
  );
};

export default ResultadosBusca;
