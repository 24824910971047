import React from 'react';
import { Modal, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa'; // Importa o ícone de interrogação da react-icons

interface Produto {
  id: string;
  title: string;
  thumbnail: string;
  price: number;
  seller: {
    nickname: string;
  };
  condition: string;
  attributes?: Array<{
    id: string;
    value_name: string;
  }>;
  available_quantity: number;
  shipping: {
    free_shipping: boolean;
  };
  seller_address?: {
    city?: {
      name: string;
    };
  };
  permalink: string;
}

interface ComparisonModalProps {
  show: boolean;
  onHide: () => void;
  produtosComparacao: Produto[];
}

const ComparisonModal: React.FC<ComparisonModalProps> = ({
  show,
  onHide,
  produtosComparacao,
}) => {
  // Calcular a média dos preços
  const mediaPreco =
    produtosComparacao.reduce((acc, produto) => acc + produto.price, 0) /
    produtosComparacao.length;

  // Calcular a diferença entre o menor e o maior preço
  const menorPreco = Math.min(...produtosComparacao.map((p) => p.price));
  const maiorPreco = Math.max(...produtosComparacao.map((p) => p.price));
  const diferencaPreco = maiorPreco - menorPreco;

  return (
    <Modal show={show} onHide={onHide} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Comparação de Produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Exibir a média e a diferença de preços com Tooltip */}
        <div
          style={{ padding: '10px', marginBottom: '20px', textAlign: 'center' }}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                A média é calculada somando os preços dos produtos e dividindo
                pelo número de produtos selecionados.
              </Tooltip>
            }
            container={document.body} // Garante que o tooltip não seja cortado
          >
            <h4>
              Média de Preço: R$ {mediaPreco.toFixed(2)}{' '}
              <FaQuestionCircle style={{ cursor: 'pointer' }} />
            </h4>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                A diferença é calculada subtraindo o menor preço do maior preço
                entre os produtos comparados.
              </Tooltip>
            }
            container={document.body} // Garante que o tooltip não seja cortado
          >
            <p>
              Diferença entre o maior e menor preço: R${' '}
              {diferencaPreco.toFixed(2)}{' '}
              <FaQuestionCircle style={{ cursor: 'pointer' }} />
            </p>
          </OverlayTrigger>
        </div>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Característica</th>
              {produtosComparacao.map((produto) => (
                <th key={produto.id}>{produto.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Imagem</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  <img
                    src={produto.thumbnail}
                    alt={produto.title}
                    width="100"
                    height="100"
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Preço</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>R$ {produto.price.toFixed(2)}</td>
              ))}
            </tr>
            <tr>
              <td>Vendedor</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>{produto.seller.nickname}</td>
              ))}
            </tr>
            <tr>
              <td>Condição</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  {produto.condition === 'new' ? 'Novo' : 'Usado'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Marca</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  {produto.attributes?.find((attr) => attr.id === 'BRAND')
                    ?.value_name || 'N/A'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Disponibilidade</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  {produto.available_quantity > 0 ? 'Disponível' : 'Esgotado'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Frete Grátis</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  {produto.shipping.free_shipping ? 'Sim' : 'Não'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Localidade</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  {produto.seller_address?.city?.name || 'N/A'}
                </td>
              ))}
            </tr>
            <tr>
              <td>Ações</td>
              {produtosComparacao.map((produto) => (
                <td key={produto.id}>
                  <a
                    href={produto.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver Anúncio
                  </a>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ComparisonModal;
